import React from 'react';

import { CreditAppState } from '../CreditApp.model';
import { Validator } from '../formValidator';
import { ADOBE_TAGS } from '../types/AdobeTags';
import { Mutator } from '../types/ContextTypes';
import { KeyValuePair } from '../types/KeyValuePair';
import { CreditAppActionTypes } from '../types/PayloadTypes';
import { CustomValidator } from '../utils/customValidation';
import { constructSCADataLayer, sendAdobeEvents } from '../utils/eventUtils';
import { isEmptyObject } from '../utils/helper';
import { produce } from '../utils/Immer.class';
import { PAGES, URLPaths } from './../types/Constants';
interface States {
    [key: string]: {
        state: any;
        setter?: React.Dispatch<React.SetStateAction<any>>;
        customValidators?: CustomValidator[];
    };
}

/**
* loop through the provided stateModel <States> and calls each model prop useState.dispatch.
* if there is no error dispatches the payload to contextApi
* @param dispatch Context Api dispatch
* @param mutator Context Api state mutator
* @typeparam <States> has all the payload shape as example below
* {
		primaryApplicant: {
				state: primaryApplicant,
				setter: setPrimaryApplicant,
				customValidators: Any CustomValidator
			},
		jointApplicant: {
			state: jointApplicant,
			setter: setJointApplicant,
		},
	}

* @param postFunc any function that cab be run after dispatch like handleRoutes
*/
export const useSubmit = (
    dispatch: React.Dispatch<CreditAppActionTypes>,
    mutator: Mutator<any, CreditAppState>,
    stateModel: States,
    postFunc?: () => void,
    location?: Location,
    experience?: boolean
): KeyValuePair => {
    let hasError = false;
    const payload: any = {};
    const errors: KeyValuePair = {};
    Object.entries(stateModel).forEach((entry) => {
        // sectionName is exp : "primaryApplicant" and "jointApplicant"
        // section is {
        //		state: primaryApplicant,
        //		setter: setPrimaryApplicant,
        //      customValidators: Any CustomValidator
        // }
        const [sectionName, section] = entry;
        const { state, setter } = section;
        // do not run the getErrors if setter is not defined - hence, reconciliation of form is ignored
        const sectionError = setter ? Validator.getErrors(state) : {};
        section.customValidators?.forEach((cv: CustomValidator) => {
            if (!cv.validate()) {
                sectionError[cv.fieldMessage.field] = cv.fieldMessage.message;
            }
        });
        const anySectionError = !isEmptyObject(sectionError);
        hasError = hasError || anySectionError;
        // getErrors function changes the state exp: adds the errors and
        // sets the hasError for each formField setter is the setter from useState that
        // needs to be called to cause react reconciliation with deep copy of changes
        if (setter) {
            setter(produce(state));
        }
        payload[sectionName] = state;
        if (anySectionError) errors[sectionName] = sectionError;
    });

    if (!hasError) {
        dispatch({
            mutator,
            payload
        });
        if (location) {
            const pathName = location.pathname as URLPaths;

            if (experience) {
                const adobeStandaloneClickedCTA = PAGES[pathName].adobeStandaloneClickedCTA;
                if (adobeStandaloneClickedCTA) constructSCADataLayer(adobeStandaloneClickedCTA);
            } else {
                const adobeTagCtaClicked = PAGES[pathName].adobeClickedCTA;
                if (adobeTagCtaClicked && adobeTagCtaClicked !== ADOBE_TAGS.DR_CREDIT_APP_DISPLAYED) {
                    sendAdobeEvents(adobeTagCtaClicked);
                }
            }
        }
        if (postFunc) postFunc();
    }
    return errors;
};
